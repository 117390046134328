.ant-select-dropdown {
    z-index: 1000; /* 더 높은 값으로 설정 */
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 10;
  border-color: pink;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  padding: 0;
  border-color: pink;
}
.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  font-size: 16px;
  font-weight: 500;
  
}
.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  height: 52px;
  padding: 0px 10px 0px 16px;
  
  color: #101010; /* 선택한 아이템을 표시하는 텍스트 색상  */
}

:where(.css-dev-only-do-not-override-1hpnbz2).ant-select-dropdown .ant-select-item {
    height: 48px;
    padding: 0px 16px;
}

/* 드롭다운 클릭 시, 셀렉트 박스 영역 */
:where(.css-dev-only-do-not-override-1hpnbz2).ant-select-outlined:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer):hover .ant-select-selector {
    border: 1px solid #333438;
    box-shadow: none;
}

/* 드롭다운 클릭 시, 드롭 박스 영역 */
.ant-select-dropdown.css-dev-only-do-not-override-1hpnbz2.ant-select-dropdown-placement-bottomLeft {
    border: 1px solid #333438;
}
